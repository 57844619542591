.footer-container .gradient-box {
  background-image: linear-gradient(to right, #1d2671, #c33764);
}

.bg-banner-container {
  background: url("../assets/images/banner/BG.png");
  background-size: cover;
  background-repeat: no-repeat;
}
/* animation */
@keyframes movingTopToBottom {
  0% {
    top: 50px;
  }
  100% {
    top: 90px;
  }
}
#divTAReviews {
  animation: movingTopToBottom 3s ease infinite;
  -webkit-animation: movingTopToBottom ease 3s infinite;
  position: absolute;
}
/*  */
.homepage-container .bg-banner-container .text-shadow {
  text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.8);
}
.homepage-container .bg-linear-gradient-home {
  height: 40px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.02), #111111);
  margin-top: -35px;
}
.homepage-container .player-container div:nth-child(1) {
  width: 100% !important;
  height: 100% !important;
}
@media only screen and (max-width: 1800px) and (min-width: 1536px) {
  .homepage-container .service-box-container {
    width: 35% !important;
  }
}
.homepage-container .desktop-services-box {
  display: block;
}
.homepage-container .mobile-services-box {
  display: none;
}
.homepage-container .desktop-left-box {
  width: 25%;
}
@media (max-width: 1250px) and (min-width: 320px) {
  .homepage-container .desktop-services-box {
    display: none;
  }
  .homepage-container .desktop-left-box {
    width: 100% !important;
  }
  .homepage-container .mobile-services-box {
    display: block !important;
  }
}

.homepage-container .border1 {
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    rgb(29, 4, 138),
    rgb(228, 18, 102)
  );
}
.homepage-container .border2 {
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    rgb(5, 104, 109),
    rgb(6, 175, 119)
  );
}
.homepage-container .border3 {
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    rgb(173, 15, 212),
    rgb(79, 4, 88)
  );
}
.homepage-container .border4 {
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    rgba(25, 141, 209, 0.932),
    rgba(15, 60, 150, 0.959)
  );
}
.homepage-container .border5 {
  border-radius: 20px;
  background-image: linear-gradient(to right, #054b7f, #9dc582);
}

.homepage-container .headshot {
  width: 100%;
  border: 1px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
}

.homepage-container .img-wrapper {
  /* background-color:#f8f8f8; */
  opacity: 0;
}

.homepage-container .img-wrapper.change {
  opacity: 1;
  transition: all 2s;
}

.homepage-container .service-gradient-box1 {
  background-image: linear-gradient(to right, #252770, #bc3564);
}
.homepage-container .service-gradient-box2 {
  background-image: linear-gradient(to right, #195f9e, #42caa4);
}
.homepage-container .service-gradient-box3 {
  background-image: linear-gradient(to right, #195f9e, #42caa4);
}
.homepage-container .service-gradient-box4 {
  background-image: linear-gradient(to right, #8e00ca, #590093);
}
.homepage-container .service-gradient-box5 {
  background-image: linear-gradient(to right, #2c9ae2, #102f58);
}
.homepage-container .service-gradient-box6 {
  background-image: linear-gradient(to right, #0d5080, #91bd7e);
}

@keyframes rotate-full {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homepage-container .animated {
  animation-duration: 20s;
  animation-fill-mode: both;
}
.animated .infinite {
  animation-iteration-count: infinite;
}

.homepage-container .box1 {
  transform: rotate(0deg);
}
.homepage-container .box1 img {
  max-width: 100%;
  border-radius: 100%;
}
.homepage-container .rotate {
  animation: rotation-full 20s infinite linear;
}
.homepage-container .rotate-full {
  animation: infinite-spinning 20s infinite linear;
}
