.our-services-container .design-box {
  background: linear-gradient(
    90deg,
    rgba(29, 38, 113, 0.2) 0%,
    rgba(195, 55, 100, 0.2) 100%
  );
}
.our-services-container .design-box2 {
  background: linear-gradient(
    90deg,
    rgba(24, 90, 157, 0.2) 0%,
    rgba(67, 206, 162, 0.2) 100%
  );
}
.our-services-container .design-box3 {
  background: linear-gradient(
    90deg,
    rgba(148, 0, 211, 0.2) 0%,
    rgba(75, 0, 130, 0.2) 100%
  );
}
.our-services-container .design-box4 {
  background: linear-gradient(
    90deg,
    rgba(45, 158, 224, 0.2) 0%,
    rgba(47, 128, 237, 0.2) 50%,
    rgba(9, 30, 58, 0.2) 100%
  );
  border-radius: 40px;
}
.our-services-container .design-box5 {
  background: linear-gradient(90deg, #00467f 0%, #a5cc82 100%);
  border-radius: 40px;
}
.our-services-container .design-box6 {
  background: linear-gradient(90deg, #182848 0%, #4b6cb7 100%);
  border-radius: 40px;
}

.our-services-container .img-wrapper {
  opacity: 0;
}

.our-services-container .img-wrapper.change {
  opacity: 1;
  transition: all 2s;
}
.our-services-container .bg-image-1 {
  /* background-image: url("../assets/images/ourServices/box3.png"); */
  background-image: url("../assets/images/ourServices/box11.webp");
  width: 900px;
  height: 1800px;
  background-size: contain;
  background-repeat: repeat;
}
.our-services-container .bg-image-2 {
  background-image: url("../assets/images/services/mobile12.webp");
  width: 900px;
  height: 2800px;
  background-size: contain;
  background-repeat: repeat-y;
  margin-top: 400px;
}
.our-services-container .bg-image-3 {
  background-image: url("../assets/images/ourServices/box1.webp");
  width: 900px;
  height: 3800px;
  background-size: contain;
  background-repeat: repeat;
  margin-top: 400px;
}